<template>
    <div>
        <Navbar />
        <Header :category="'organizers'" :bannerTitle="bannerTitle" :breadCrumb="borrowSpaceCrumb" />
        <ExhibitionBody />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import ExhibitionBody from './ExhibitionBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'ExhibitionMain',
        components: {
            Navbar,
            Header,
            ExhibitionBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                selected: null,
                year: [{
                    value: null,
                    text: '年份'
                }, ],
                month: [{
                    value: null,
                    text: '月份'
                }, ],
                classArr: [{
                    value: null,
                    text: '類別'
                }, ],
                bannerTitle: "ACTIVITY<br>EXTENSION",
                borrowSpaceCrumb: [
                    "設施服務"
                ],
            }
        }
    }
</script>
